import './Templates.css';
import React, { useState, useEffect } from 'react';
import { useNavigate, useOutletContext } from "react-router-dom";
import api from '../../api/axiosInstance';
import { useModal } from '../../contexts/ModalContext';
import { Editor } from '@tinymce/tinymce-react';

const Post = () => {
    const navigate = useNavigate();
    const [setBreadcrumb, setBreadcrumbLink] = useOutletContext();
    const [proceeding, setProceeding] = useState(false);
    const [templateName, setTemplateName] = useState('');
    const [subject, setSubject] = useState('');

    const { openModal } = useModal();
    const [editorHtml, setEditorHtml] = useState('');

    const handleEditorChange = (content, editor) => {
        setEditorHtml(content);
    };

    useEffect(() => {
        setBreadcrumb(['Dashboard', 'Templates', 'Create']);
        setBreadcrumbLink(['/dashboard', '/dashboard/Templates']);
    }, [setBreadcrumb, setBreadcrumbLink]);

    const handelUpdateTemplate = async (event) => {
        event.preventDefault();
        try {
            setProceeding(true);
            const response = await api.post(`/templates`,
                {
                    templateName: templateName,
                    subject: subject,
                    body: editorHtml
                }
            );
            if (!response.error) {
                openModal('success', response.data.message);
                setProceeding(false);
                navigate('/dashboard/Templates');
            } else {
                openModal('failure', response.data.message);
                setProceeding(false);
            }
        } catch (error) {
            if (error.message === 'Network Error') {
                openModal('success', "No Internet Connection.");
            } else {
                openModal('success', error.message);
            }
        }
    };

    return (
        <>
            <div id="default-modal" tabindex="-1" class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full flex" aria-modal="true" role="dialog">
   <div class="relative p-4 w-full max-w-2xl max-h-full">
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
         <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 class="text-xl font-semibold text-gray-900 dark:text-white">⚡️Dynamic Tokens</h3>
            <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
               <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"></path>
               </svg>
               <span class="sr-only">Close modal</span>
            </button>
         </div>
         <div class="p-4 md:p-5 space-y-4">
            <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">With less than a month to go before the European Union enacts new consumer privacy laws for its citizens, companies around the world are updating their terms of service agreements to comply.</p>
            <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">The European Union’s General Data Protection Regulation (G.D.P.R.) goes into effect on May 25 and is meant to ensure a common set of data rights in the European Union. It requires organizations to notify users as soon as possible of high-risk data breaches that could personally affect them.</p>
         </div>
         <div class="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600"><button data-modal-hide="default-modal" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">I accept</button><button data-modal-hide="default-modal" type="button" class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Decline</button></div>
      </div>
   </div>
</div>


            <div className="p-6 bg-white sm:p-8 dark:bg-gray-800 shadow-lg rounded-lg">
                <div className="grid grid-cols-2">
                    <div className="col flex items-end">
                        <h3 className="text-xl pb-3 font-bold text-gray-900 dark:text-white">Create Template</h3>
                    </div>
                    <div className="col text-sm mb-2 w-full text-right font-normal text-gray-500 dark:text-gray-400">
                        {/* Add any actions here */}
                    </div>
                </div>

                <form method='template' className="space-y-6 mt-6" onSubmit={(e) => { handelUpdateTemplate(e) }}>
                    <div className="grid grid-cols-6 sm:grid-cols-12 gap-6">
                        <div className="col-span-4">
                            <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Template Name</label>
                            <input type="text" id="name" onChange={(e) => setTemplateName(e.target.value)} value={templateName} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                        </div>
                        <div className="col-span-8">
                            <label htmlFor="subject" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Subject</label>
                            <input type="text" id="subject" onChange={(e) => setSubject(e.target.value)} value={subject} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                        </div>
                        <div className="col-span-6 sm:col-span-12">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Template Content

                                <button type="button" className="py-1 px-2.5 ms-2 float mb-2 text-xs text-xs font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">⚡️Dynamic Tokens</button>

                            </label>
                            <Editor
                                value={editorHtml}
                                init={{
                                    license_key: 'gpl',
                                    height: 520,
                                    menubar: 'edit view insert format tools table',
                                    plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
                                    toolbar: 'undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen preview code print | insertfile image media link anchor codesample | ltr rtl',
                                    toolbar_sticky: true,
                                    autosave_ask_before_unload: true,
                                    autosave_interval: "30s",
                                    autosave_restore_when_empty: false,
                                    autosave_retention: "2m",
                                    image_advtab: true,
                                    content_css: '//www.tiny.cloud/css/codepen.min.css',
                                    image_caption: true,
                                    quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                                    noneditable_noneditable_class: "mceNonEditable",
                                    toolbar_mode: 'sliding',
                                    contextmenu: "link image imagetools table",
                                    branding: false,
                                }}
                                onEditorChange={handleEditorChange}
                            />
                        </div>
                        <div className="col-span-6 sm:col-span-12">
                            <button className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800" type="submit">
                                {proceeding ? (<>
                                    <div className='inline mr-3' role="status">
                                        <svg aria-hidden="true" className="w-5 h-5 inline text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" /><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" /></svg>
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </>) : (<></>)}Create Template</button>
                        </div>
                    </div>
                </form>
            </div>
        </>

    );
};

export default Post;